<template>
  <div class="card-panel pt-0">
    <div style="height: 60px, background-color: #fff">
      <div class="row">
        <div class="col-7">
          <span class="title">{{ titulo }}</span>
        </div>
      </div>
    </div>
    <DevolucaoPorItens v-if="devolucaoPorItem" />
    <DevolucaoPorNotas v-else />
  </div>
</template>

<script>
  import loading from '@/common/loading';
  import DevolucaoPorItens from './DevolucaoPorItens.vue';
  import DevolucaoPorNotas from './DevolucaoPorNotas.vue';
  import { mapState } from 'vuex';
  import { DEVOLUCAO_TIPO } from '@/core/enums/devolucao-tipo.enum.js';

  export default {
    name: 'Devolucao',

    components: {
      DevolucaoPorItens,
      DevolucaoPorNotas,
    },
    mounted() {
      loading.ocultar();
    },

    computed: {
      ...mapState({
        identificacao: (state) => state.devolucao.Identificacao,
      }),

      devolucaoPorItem() {
        return this.identificacao.Tipo == DEVOLUCAO_TIPO.PorItem;
      },

      titulo() {
        return this.devolucaoPorItem ? 'Itens' : 'Notas';
      },
    },
  };
</script>

<style scoped>
  .title {
    cursor: default !important;
  }
</style>
