<template>
  <div class="input-group input-group-sm input-size">
    <button tabindex="-1" type="button" @click.stop="update(-1)" class="btn btn-default btn-sm input-group-prepend">
      <span>
        <i class="icon icon-lx-remove icon-lx-sm"></i>
      </span>
    </button>

    <currency-input
      ref="input"
      v-model="valor"
      :distraction-free="true"
      :allow-negative="false"
      :auto-decimal-mode="preencherDecimalAutomaticamente"
      :currency="null"
      :precision="precisao"
      :value-range="faixaValor"
      class="form-control text-right border border-right-0 border-left-0"
      @blur="onBlur"
    />

    <div class="input-group-append">
      <div class="input-group-text bg-white border border-left-0">
        <small>({{ unidade }})</small>
      </div>
    </div>

    <button tabindex="-1" type="button" @click.stop="update(1)" class="btn btn-default btn-sm input-group-append">
      <span>
        <i class="icon icon-lx-plus icon-lx-sm"></i>
      </span>
    </button>
  </div>
</template>

<script>
  import { CurrencyInput, setValue, getValue } from 'vue-currency-input';

  export default {
    name: 'InputQuantity',
    components: {
      CurrencyInput,
    },
    props: {
      value: {
        type: Number,
        default: 0,
      },
      precisao: {
        type: [Number, Object],
        default: 0,
      },
      unidade: {
        type: String,
        default: 'Un',
      },
      faixaValor: {
        type: Object,
        default() {
          return { min: 0.0000000001 };
        },
      },
      preencherDecimalAutomaticamente: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      valorMinimo() {
        let precisao = isNaN(this.precisao) ? this.precisao.min : this.precisao;
        return 10 ** -precisao;
      },
    },
    watch: {
      value: {
        handler(newValue, oldValue) {
          this.valor = newValue;
          this.valorAlterado = {
            new: newValue,
            old: oldValue
          };
        },
        immediate: true,
      },
      valor(newValue) {
        this.$emit('input', newValue);
      },
      valorAlterado(value) {
        this.$emit('alteracao', value);
      },
    },
    created() {
      this.validarValorInicial();
      this.validarValor();
    },
    data() {
      return {
        valor: 0,
        valorAlterado: {
          new: 0,
          old: 0
        },
      };
    },
    methods: {
      onBlur() {
        this.validarValor();
      },
      update(value) {
        setValue(this.$refs.input, getValue(this.$refs.input) + value);
        this.validarValor();
      },
      validarValorInicial() {
        if (this.valor >= this.valorMinimo) return;

        if (this.valorMinimo > this.faixaValor.min && this.valor < this.valorMinimo && this.valor > this.faixaValor.min)
          this.valor = this.valorMinimo;
      },
      validarValor() {
        let tempValor = this.valor;
        if (!this.valor && this.valor != 0) tempValor = this.faixaValor.min;
        if (this.valor < this.faixaValor.min) tempValor = this.faixaValor.min;
        if (this.faixaValor.min > 0 && this.valor <= this.faixaValor.min && this.valor < this.valorMinimo)
          tempValor = this.valorMinimo;

        this.$nextTick(() => {
          this.valor = tempValor;
        });
      },
    },
  };
</script>

<style scoped>
  .input-size {
    min-width: 9rem;
  }
</style>
