export const DEVOLUCAO_CODIGOS_ARQUIVO_UPLOAD = {
  CodigoQuantidade: { Id: 1, Descricao: 'Código do Produto e Quantidade' },
  CodBarrasQuantidade: { Id: 2, Descricao: 'Código de Barras e Quantidade' },
  SerialQuantidade: { Id: 3, Descricao: 'Serial e Quantidade' },
  CodAuxiliarQuantidade: { Id: 4, Descricao: 'Código Auxiliar e Quantidade' },
  CodigoLoteQuantidade: { Id: 5, Descricao: 'Código do Produto, Lote e Quantidade' },
  CodigoQuantidadeChaveNF: { Id: 6, Descricao: 'Código do Produto, Quantidade e Chave NF-e de origem' },
  CodBarrasQuantidadeChaveNF: { Id: 7, Descricao: 'Código de Barras, Quantidade e Chave NF-e de origem' },
  SerialQuantidadeChaveNF: { Id: 8, Descricao: 'Serial, Quantidade e Chave NF-e de origem' },
  CodAuxiliarQuantidadeChaveNF: { Id: 9, Descricao: 'Código Auxiliar, Quantidade e Chave NF-e de origem' },
};
