import { render, staticRenderFns } from "./PesquisaAvancadaProdutos.vue?vue&type=template&id=da28ca88&scoped=true"
import script from "./PesquisaAvancadaProdutos.vue?vue&type=script&lang=js"
export * from "./PesquisaAvancadaProdutos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da28ca88",
  null
  
)

export default component.exports