<template>
  <div>
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-75 w-sm-100 position-relative'"
      :classesAdicionaisBackDrop="'align-items-start'"
      :classesAdicionaisHeader="'d-none'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalPesquisaProdutos"
    >
      <div slot="body">
        <div class="modal-header no-padding" @keydown.esc="fechar()">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      ref="buscaEmMassaInput"
                      @keyup.enter="buscarItemEmMassa"
                      v-model="CodigoBarrasItem"
                      placeholder="Informe a referência, nome ou código auxiliar"
                    />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-primary btn-sm float-right" @click="buscarItemEmMassa">
                        <span class="text-center">Pesquisar</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body no-padding">
          <div class="table-responsive pt-2" v-if="ItensInsercaoEmMassa.length > 0" style="max-height: 60vh">
            <table class="table table-hover table-sm table-visualization">
              <thead class="table-visualization__head">
                <tr class="overflow-hidden">
                  <th width="80%">Item</th>
                  <th width="20%" class="text-center">Quantidade</th>
                </tr>
              </thead>
              <tbody class="table-visualization__body">
                <tr v-for="item in ItensInsercaoEmMassa" v-bind:key="item.CodigoProduto" class="overflow-hidden">
                  <td class="align-middle">
                    <span>{{ item.Descricao }}</span>
                    <small class="d-blockx font-weight-boldx">
                      (Ref: {{ item.Referencia }} | Cód: {{ item.CodigoProduto }})
                    </small>
                  </td>
                  <td class="align-middle text-center">
                    <input-quantity
                      v-model="item.Quantidade"
                      :unidade="item.UnidadeVenda"
                      :precisao="item.UnidadeVendaCasasDecimais"
                      :faixaValor="{ min: 0 }"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12 pt-2 d-flex justify-content-center" v-else>
            <div class="text-center">
              <a>
                <i class="fa fa-filter lx-2x fa-fw"></i>
                <span class="pt-1">
                  <strong> Nenhum produto sendo listado, você pode pesquisar por referência, nome ou código auxiliar. </strong>
                </span>
              </a>
            </div>
          </div>
        </div>

        <div class="modal-footer no-padding">
          <template v-if="MensagemBuscaLimitada">
            <small>
              {{ MensagemBuscaLimitada }}
            </small>
          </template>

          <div class="pt-2">
            <button type="button" class="btn btn-default ml-2" data-dismiss="modal" @click="fechar">Fechar</button>
            <button type="button" class="btn btn-primary ml-2" data-dismiss="modal" @click="inserirItensEmMassa"
              >Adicionar</button
            >
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>
<script>
  import InputQuantity from '@/components/shared/input/InputQuantity.vue';
  import { mapState, mapGetters } from 'vuex';
  import swal from '@/common/alerts';
  import loading from '@/common/loading';

  export default {
    name: 'PesquisaAvancadaProdutos',
    components: {
      InputQuantity,
    },

    props: {
      IdEmpresaCusto: {
        type: Number,
        required: false,
        default: 1,
      },
      IdEmpresa: {
        type: Number,
        required: false,
        default: 1,
      },
      IdTabelaPreco: {
        type: Number,
        required: false,
        default: 1,
      },
      IdDeposito: {
        type: Number,
        required: false,
        default: 1,
      },
      UsaCustoPorEmpresa: {
        type: Boolean,
        required: false,
        default: true,
      },
      ProdutosDisponiveisPorEmpresa: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        Arquivo: null,
        MensagemBuscaLimitada: '',
        ItensInsercaoEmMassa: [],
        CodigoBarrasItem: '',
      };
    },
    computed: {
      ...mapState({
        Parametros: function () {
          return { parametros: 'parametros' };
        },
      }),
      NomeArquivo: function () {
        if (!this.Arquivo) {
          return '';
        }

        return this.Arquivo.name;
      },
      pesquisaProdutoAceitaAlfanumerico() {
        return this.$store.state.devolucao.Configuracoes.PesquisaProdutoAceitaCodigoAlfanumerico;
      },
      idFornecedor() {
        return this.$store.state.devolucao.Identificacao.Fornecedor.Codigo;
      },
    },

    methods: {
      buscarItemEmMassa: function () {
        var self = this;

        self.ItensInsercaoEmMassa = [];
        self.MensagemBuscaLimitada = '';

        loading.exibir();

        var parametrosParaPesquisarProdutos = {
          Texto: this.CodigoBarrasItem,
          AceitaCodigoAlfanumerico: this.pesquisaProdutoAceitaAlfanumerico,
          IdFornecedor: this.idFornecedor,
        };

        self.$refs.buscaEmMassaInput.focus();

        this.$store
          .dispatch('devolucao/listarProdutos', parametrosParaPesquisarProdutos)
          .then(async (response) => {
            if (response.data == null) {
              loading.ocultar();
              return;
            }
            if (response.data.length == 100) {
              self.MensagemBuscaLimitada =
                'Sua busca foi limitada nos 100 primeiros resultados, adicione termos para melhorar o filtro';
            }
            self.ItensInsercaoEmMassa = response.data;
            self.ItensInsercaoEmMassa.forEach(function (item) {
              item.Quantidade = 0;
            });
            loading.ocultar();
          })
          .catch((error) => {
            console.log(error);
            loading.ocultar();
            swal.exibirMensagemErro(error.join('<br />'));
          });
      },

      fechar() {
        this.$emit('fechar');
      },

      inserirItensEmMassa() {
        var self = this;
        var qtdeItensParaInserir = this.ItensInsercaoEmMassa.length;

        loading.exibir();

        var parametrosListarProdutosReferenciaEntradas = {
          Produtos: [],
          AceitaCodigoAlfanumerico: this.pesquisaProdutoAceitaAlfanumerico,
          IdFornecedor: this.idFornecedor,
        };

        for (var i = 0; i < qtdeItensParaInserir; i++) {
          if (this.validarInsercaoItemMassa(this.ItensInsercaoEmMassa[i])) {
            let item = {
              Codigo: this.ItensInsercaoEmMassa[i].CodigoProduto,
              Quantidade: this.ItensInsercaoEmMassa[i].Quantidade,
            };
            parametrosListarProdutosReferenciaEntradas.Produtos.push(item);
          }
        }

        if (parametrosListarProdutosReferenciaEntradas.Produtos.length == 0) {
          loading.ocultar();
          swal.exibirMensagemErro('Nenhum produto selecionado');
          return;
        }

        this.$store
          .dispatch('devolucao/listarProdutosReferenciaEntradas', parametrosListarProdutosReferenciaEntradas)
          .then((response) => {
            loading.ocultar();

            if (!response || !response.data.Produtos || response.data.Produtos.length == 0) {
              swal.exibirMensagemAlerta('Não foi possível inserir os produtos.');
              return;
            }
            var qtdeItens = response.data.Produtos.length;

            self.MensagemBuscaLimitada = '';

            if (qtdeItens > 0)
              self.MensagemBuscaLimitada = 'Exibindo ' + response.data.Produtos.length + (qtdeItens > 1) ? ' itens' : ' item';

            var codigosNaoEncontrados = response.data.CodigosNaoEncontrados;

            if (codigosNaoEncontrados && codigosNaoEncontrados.length > 0) {
              var mensagemProdNaoEncontrado =
                codigosNaoEncontrados.length > 1
                  ? 'Os seguintes produtos não foram encontrados: '
                  : 'O seguinte produto não foi encontrado: ';
              mensagemProdNaoEncontrado += '<br/><br/>' + response.data.CodigosNaoEncontrados.join(', ');
              swal.exibirMensagemAlerta(mensagemProdNaoEncontrado);
            }

            self.$emit('retornarProdutos', response.data.Produtos);
          })
          .catch((error) => {
            loading.ocultar();
            swal.exibirMensagemErro('Ocorreu um problema');
            return;
          });
      },

      validarInsercaoItemMassa: function (item) {
        return item.Quantidade && item.Quantidade > 0;
      },

      inserirItem(item) {},

      itemExigeControleSerial: function (item) {
        return item.ControlaSerial && this.$store.state.devolucao.Configuracoes.listasGerais.NaturezasOperacao.AtualizarEstoque; // Ajustar isso
      },
    },
  };
</script>
<style scoped></style>
