<template>
  <div class="row col-7 align-items-end">
    <template v-if="UI.TipoBusca == TIPO_BUSCA.PorNumeroSerie">
      <div class="col-6 pr-2">
        <label for="inputDocumento" class="justify-content-start">Número</label>
        <input
          id="inputDocumento"
          type="text"
          class="form-control form-control-sm border mr-2 w-100"
          placeholder="Informe o número da NF-e"
          @keyup.enter.prevent="buscarNota"
          v-model="Pesquisa.Numero"
          autocomplete="off"
        />
      </div>
      <div class="col-3 pl-0 pr-2">
        <label for="inputSerie" class="justify-content-start">Série</label>
        <input
          id="inputSerie"
          type="text"
          class="form-control form-control-sm border w-100"
          placeholder="Informe a série"
          @keyup.enter.prevent="buscarNota"
          v-model="Pesquisa.Serie"
          autocomplete="off"
        />
      </div>
    </template>
    <template v-else>
      <div class="col-9 pr-2">
        <label for="inputChave" class="justify-content-start">Chave</label>
        <the-mask
          id="inputChave"
          :mask="['#### #### #### #### #### #### #### #### #### #### ####']"
          :type="'text'"
          class="form-control form-control-sm border mr-2 w-100"
          placeholder="Informe a chave de acesso da NF-e"
          @keyup.enter.prevent="buscarNota"
          v-model="Pesquisa.Chave"
          autocomplete="off"
        />
      </div>
    </template>
    <button id="button-inserir" type="button" class="btn btn-primary form-control form-control-sm col-3 p-0" @click="buscarNota"
      >Inserir</button
    >
    <div class="align-items-end col-7 pt-1">
      <button
        id="button-trocar-tipo-pesquisa"
        type="button"
        class="btn btn-default form-control form-control-sm col-7 p-0 w-100 min-w-160 text-truncate"
        @click="alterarTipoPesquisa"
        >{{ labelAlterarPesquisaNota }}</button
      >
    </div>
  </div>
</template>

<script>
  import loading from '@/common/loading';
  import swal from '@/common/alerts';
  import { mapState } from 'vuex';
  import { TheMask } from 'vue-the-mask';

  const TIPO_BUSCA = Object.freeze({ PorNumeroSerie: 0, PorNota: 1 });

  export default {
    name: 'DevolucaoPesquisaPorNota',

    data() {
      return {
        TIPO_BUSCA,
        UI: {
          TipoBusca: TIPO_BUSCA.PorNumeroSerie,
        },
        Pesquisa: {
          Numero: '',
          Serie: '',
          Chave: '',
        },
      };
    },

    mounted() {
      loading.ocultar();
      this.definirFoco();
    },

    computed: {
      ...mapState({
        notasAssociadas: (state) => state.devolucao.NotasAssociadas,
      }),

      tipoPesquisaPorNumeroChave() {
        return this.UI.TipoBusca == TIPO_BUSCA.PorNumeroSerie;
      },

      labelAlterarPesquisaNota() {
        return this.tipoPesquisaPorNumeroChave ? 'Pesquisar pela Chave NF-e' : 'Pesquisar por Número/Série';
      },
    },

    methods: {
      buscarNota() {
        if (!this.validarParametrosPesquisa()) {
          if (this.tipoPesquisaPorNumeroChave)
            swal.exibirMensagemAlerta('Por favor informe o número e série da nota para inserir na devolução.');
          else swal.exibirMensagemAlerta('Por favor informe a chave da nota para inserir na devolução.');
          return;
        }

        if (!this.$parent.validarFornecedor()) {
          return;
        }

        var parametrosBusca = {
          Numero: this.Pesquisa.Numero,
          Serie: this.Pesquisa.Serie,
          Chave: this.Pesquisa.Chave,
        };

        loading.exibir();
        this.$store
          .dispatch('devolucao/ObterNotaEntrada', parametrosBusca)
          .then((response) => {
            if (!response.data) {
              loading.ocultar();
              swal.exibirMensagemErro(
                'A Nota Fiscal que você informou não foi localizada no sistema, confira se você digitou corretamente.<br/><br/>Lembre-se: Apenas os produtos cuja a nota de entrada foi lançada pelas <i>rotinas de entrada via XML</i> poderão ser devolvidas por esta solução.',
              );
              return;
            }

            if (response.data.EntradaInvalida) {
              this.exibirNotificacao(response.data.Notificacao);
              return;
            }

            if (
              this.verificarNotaJaInserida(response.data.DevolucaoReferenciada.Numero, response.data.DevolucaoReferenciada.Serie)
            ) {
              this.exibirNotificacao('Ops, a Nota Fiscal que você informou já foi inserida.');
              return;
            }
            
            this.$store.dispatch('devolucao/inserirItemNotaEntrada', response.data.Produtos);

            var devolucoes = [];
            devolucoes.push(response.data.DevolucaoReferenciada);
            this.$parent.atualizarNotasReferenciadas(devolucoes);

            loading.ocultar();
          })
          .catch((error) => {
            this.exibirNotificacao('Ocorreu um problema');
            return;
          });

        this.reiniciarPesquisa();
      },

      exibirNotificacao(notificacao) {
        loading.ocultar();
        swal.exibirMensagemAlerta(notificacao);
      },

      validarParametrosPesquisa() {
        if (this.tipoPesquisaPorNumeroChave) {
          return this.Pesquisa.Numero && this.Pesquisa.Serie;
        }

        return this.Pesquisa.Chave;
      },

      verificarNotaJaInserida(numero, serie) {
        return this.notasAssociadas.find((nota) => nota.Numero == numero && nota.Serie == serie);
      },

      alterarTipoPesquisa() {
        if (this.tipoPesquisaPorNumeroChave) this.UI.TipoBusca = TIPO_BUSCA.PorNota;
        else this.UI.TipoBusca = TIPO_BUSCA.PorNumeroSerie;
        this.definirFoco();
      },

      reiniciarPesquisa() {
        this.UI.TipoBusca = TIPO_BUSCA.PorNumeroSerie;
        this.Pesquisa.Numero = '';
        this.Pesquisa.Serie = '';
        this.Pesquisa.Chave = '';

        this.definirFoco();
      },

      definirFoco() {
        setTimeout(() => {
          if (this.UI.TipoBusca == TIPO_BUSCA.PorNumeroSerie) document.querySelector('#inputDocumento').focus();
          if (this.UI.TipoBusca == TIPO_BUSCA.PorNota) document.querySelector('#inputChave').focus();
        }, 300);
      },
    },
  };
</script>

<style scoped>
  .min-w-160 {
    min-width: 160px;
  }
</style>
