<template>
  <div>
    <BaseModal
      :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :classesAdicionaisBackDrop="'align-items-start'"
      @close="fechar()"
      :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2'"
      :classesAdicionaisFooter="'p-0 bg-white d-flex justify-content-center align-items-center w-100'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalCriticasArquivoUpload"
      :tituloModal="'Atenção!'"
    >
      <!--Criticas-->
      <div slot="body">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <img src="@/assets/Images/linx-icon-ilustrativo/alert.png" alt="check" />
            <div class="d-flex flex-column justify-content-center">
              <h3 class="text-primary my-0">{{ tituloDivergencias }}</h3>
              <span>{{ descricaoDivergencias }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-end">
            <button id="button-gerar-excel" class="btn btn-sm btn-secondary" @click="exportarCSV"> Gerar Excel </button>
          </div>
        </div>
        <!-- Tabela -->
        <div class="flex-grow-1 mt-2 justify-content-center">
          <div class="table-responsive mb-3">
            <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
              <thead>
                <tr>
                  <th class="text-center align-middle">Linhas</th>
                  <th class="text-center align-middle">Produto</th>
                  <th class="text-center align-middle">Mensagem de Crítica</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(critica, index) in criticas" :key="index">
                  <td class="text-center">{{ critica.Linha }}</td>
                  <td class="text-center">{{ critica.CodigoProduto }}</td>
                  <td class="text-center">{{ critica.Mensagem }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="modal-footer d-flex w-100 p-2 mx-2">
          <div class="font-weight-bold flex-grow-1 label-orange">Verifique o arquivo e realize uma nova importação.</div>
          <button class="btn btn-default" @click="fechar()">
            <span>Fechar</span>
          </button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
  export default {
    name: 'UploadArquivosProdutos',
    props: {
      criticas: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {};
    },
    computed: {
      tituloDivergencias() {
        return this.criticas.length > 1
          ? `${this.criticas.length} PRODUTOS COM DIVERGÊNCIAS`
          : `${this.criticas.length} PRODUTO COM DIVERGÊNCIA`;
      },
      descricaoDivergencias() {
        return this.criticas.length > 1 ? `Os produtos abaixo apresentaram críticas` : `O produto abaixo apresentou crítica`;
      },
    },
    mounted() {},
    methods: {
      fechar: function () {
        this.modalIsShow = false;
        this.$emit('fechar');
      },
      exportarCSV() {
        const nomeArquivo = `CriticasProdutosDevolucoes.xls`;
        var table = `<h2>Devolução Compra Fácil</h2>
                      <h3>Produtos com críticas na importação</h3>`;

        if (this.criticas.length > 0) {
          const linhas = this.criticas.map((i) => {
            return `
            <tr>
              <td class="text-center">${i.Linha}</td>
              <td class="text-center">${i.CodigoProduto}</td>
              <td class="text-center">${i.Mensagem}</td>
            </tr>`;
          }).join('');

          table = `${table}
          <table>
            <thead>
              <tr>
                <th>Linhas</th>
                <th>Produto</th>
                <th>Mensagem de Crítica</th>
              </tr>
            <thead>
            <tbody>${linhas}</tbody>
          </table>`;
        }
        this.download(table, nomeArquivo);
        return Promise.resolve();
      },
      download(body, nomeArquivo, nomeAba) {
        nomeAba = nomeAba || 'Relatório';
        var tableContent = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
          <head> 
            <!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${nomeAba}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
            <meta charset="UTF-8"/>
            <style>.int{ mso-number-format:"0";}</style>
          </head>
          <body>${body}</body>
          </html>`;
        if (window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([tableContent], {
            type: 'application/vnd.ms-excel',
          });
          window.navigator.msSaveBlob(blob, nomeArquivo);
        } else {
          let blob = new Blob([tableContent], {
            type: 'application/vnd.ms-excel',
          });
          var elem = window.document.createElement('a');
          const data = window.URL.createObjectURL(blob);
          elem.href = data;
          elem.download = nomeArquivo;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
    },
  };
</script>
<style scoped>
  .label-orange {
    color: #ffb200;
  }
</style>
