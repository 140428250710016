<template>
  <div>
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :classesAdicionaisBackDrop="'align-items-start'"
      :classesAdicionaisHeader="'d-none'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalPesquisaCliente"
    >
      <div slot="body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <img src="@/assets/Images/linx-icon-ilustrativo/upload.png" alt="check" />
                <p class="title">Insira itens através de um arquivo com códigos e quantidades</p>
                <div class="row">
                  <div class="col-12 d-flex justify-content-between align-items-center w-100 mb-4" v-if="showFileTypes">
                    <div class="w-30">
                      <label class="input-required fz-90">Enviar Arquivo com</label>
                    </div>
                    <div class="w-100">
                      <VueMultiselect
                        v-model="filtroTipoArquivo.selecionado"
                        :options="filtroTipoArquivo.opcoes"
                        :multiple="false"
                        :close-on-select="true"
                        placeholder="Selecione..."
                        select-label="Selecionar"
                        deselect-label="Remover"
                        selected-label="Selecionado"
                        label="Descricao"
                        open-direction="bottom"
                        :preselect-first="false"
                      >
                        <template slot="selection" slot-scope="select">
                          <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                            {{ select.values[0].Descricao }}
                          </span>
                          <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                            {{ select.values.length }} selecionados
                          </span>
                        </template>
                        <span slot="noResult">Nenhum registro encontrado</span>
                        <span slot="noOptions">Selecione um tipo de arquivo</span>
                      </VueMultiselect>
                    </div>
                  </div>
                  <div class="col-6">
                    <p class="title fz-95">
                      <span>{{ labelImportacao }}</span>
                    </p>
                    <p class="title fz-95">
                      <small>(Extensões permitidas: (*.txt) e (*.csv))</small>
                    </p>
                  </div>

                  <div class="col-6">
                    <table class="table table-sm table-bordered fz-90">
                      <thead>
                        <tr>
                          <th>exemplo.txt</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>232302390;1</td>
                        </tr>
                        <tr>
                          <td>232302390;10</td>
                        </tr>
                        <tr>
                          <td>232302390;5</td>
                        </tr>
                        <tr>
                          <td>232302390</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="NomeArquivo">
            <div class="col-8 p-0">
              <div class="btn btn-default" style="width: 100%">{{ NomeArquivo }}</div>
            </div>
            <div class="col-4 p-0">
              <button
                class="btn btn-danger"
                id="lmxta-uploadarquivo-btn-removerarquivo"
                style="width: 100%"
                v-on:click="removerArquivo"
                >Remover arquivo</button
              >
            </div>
          </div>
          <div class="row" style="position: relative" v-else>
            <label class="btn btn-primary w-100">
              Clique aqui e escolha um arquivo
              <input type="file" @change="carregarArquivo" ref="arquivos" id="lmxta-uploadarquivo-input-upload" hidden />
            </label>
          </div>
        </div>
        <div class="modal-footer no-padding">
          <div class="pt-2">
            <button
              type="button"
              class="btn btn-default ml-2"
              data-dismiss="modal"
              aria-label="Close"
              id="lmxta-uploadarquivo-btn-fechar"
              @click="fechar"
              >Fechar</button
            >
            <button
              type="button"
              class="btn btn-primary ml-2"
              data-dismiss="modal"
              v-on:click="enviarArquivo"
              id="lmxta-uploadarquivo-btn-confirmar"
              :disabled="!NomeArquivo"
            >
              Confirmar
            </button>
          </div>
        </div>

        <ModalCriticas
          v-if="modalCriticas.isShow"
          :criticas="modalCriticas.listaCriticas"
          @fechar="fecharModalCriticas"
        ></ModalCriticas>
      </div>
    </BaseModal>
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  //import Multiselect from 'vue-multiselect';
  import VueMultiselect from 'vue-multiselect';
  import { DEVOLUCAO_CODIGOS_ARQUIVO_UPLOAD } from '@/core/enums/devolucao-tipos-arquivo-upload.enum';
  import ModalCriticas from './CriticasUploadArquivosProdutos.vue'

  export default {
    name: 'UploadArquivosProdutos',
    components: { VueMultiselect, ModalCriticas },
    props: {
      showFileTypes: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tiposArquivosUpload: DEVOLUCAO_CODIGOS_ARQUIVO_UPLOAD,
        Arquivo: null,
        MensagemBuscaLimitada: '',
        filtroTipoArquivo: {
          selecionado: {
            Id: 0,
            Descricao: '',
          },
          opcoes: [],
        },
        modalCriticas:{
          listaCriticas: [],
          isShow: false,
        }
      };
    },
    computed: {
      ...mapState({
        Parametros: function () {
          return { parametros: 'parametros' };
        },
        controlaLote: (state) => state.devolucao.Configuracoes.ControlaLote,
      }),
      NomeArquivo: function () {
        if (!this.Arquivo) {
          return '';
        }

        return this.Arquivo.name;
      },
      pesquisaProdutoAceitaAlfanumerico() {
        return this.$store.state.devolucao.Configuracoes.PesquisaProdutoAceitaCodigoAlfanumerico;
      },
      pesquisaProdutoPorSerial() {
        return this.$store.state.devolucao.Configuracoes.PesquisaProdutoPorSerial;
      },
      idFornecedor() {
        return this.$store.state.devolucao.Identificacao.Fornecedor.Codigo;
      },
      labelImportacao() {
        if (this.controlaLote)
          return "Em cada linha, informe o código do produto, código de barras, código auxiliar, serial ou lote e a quantidade separada por ponto e virgula";

        return "Em cada linha, informe o código do produto, código de barras, código auxiliar, ou serial e a quantidade separada por ponto e virgula";
      }
    },
    mounted(){
      if (this.showFileTypes) this.carregarListaTiposArquivoUpload();
    },
    methods: {
      fechar: function () {
        var self = this;
        self.Arquivo = {};
        self.$emit('fechar');
      },
      enviarArquivo: function () {
        var self = this;
        self.MensagemBuscaLimitada = '';
        var payloadBodyFormData = new FormData();
        payloadBodyFormData.set('AceitaCodigoAlfanumerico', this.pesquisaProdutoAceitaAlfanumerico);
        payloadBodyFormData.set('IdFornecedor', this.idFornecedor);
        payloadBodyFormData.set('PesquisarProdutosPorSerial', this.pesquisaProdutoPorSerial);

        //MODAJOI-43677
        if (this.showFileTypes) {
          if (!this.filtroTipoArquivo.selecionado?.Id) {
            swal.exibirMensagemAlerta('Selecione o tipo de layout do arquivo a ser enviado.');
            return;
          }
          payloadBodyFormData.set('TipoArquivoReferenciaEntrada', this.filtroTipoArquivo.selecionado.Id);
        }
        payloadBodyFormData.append('arquivo', self.Arquivo);

        if (this.showFileTypes) {
          self.enviarArquivoComLayout(payloadBodyFormData);
        } else {
          //Chama Rota original, sem passar tipo de layout do arquivo
          self.enviarArquivoSemLayout(payloadBodyFormData);
        }
      },
      carregarArquivo: function () {
        var files = this.$refs.arquivos.files;

        if (files && files.length > 0) {
          this.Arquivo = files[0];
        } else {
          this.Arquivo = {};
        }
      },
      removerArquivo: function () {
        this.Arquivo = {};
      },
      enviarArquivoSemLayout(payloadBodyFormData){
        var self = this;
        loading.exibir();
        this.$store
          .dispatch('devolucao/obterItensUploadArquivo', payloadBodyFormData)
          .then((response) => {
            loading.ocultar();

            if (!response || !response.data.Produtos || response.data.Produtos.length == 0) {
              swal.exibirMensagemAlerta('Não foi possível encontrar os produtos. Verifique o arquivo importado.');
              return;
            }
            self.Arquivo = {};
            var qtdeItens = response.data.Produtos.length;

            self.MensagemBuscaLimitada = '';

            if (qtdeItens > 0)
              self.MensagemBuscaLimitada = 'Exibindo ' + response.data.Produtos.length + (qtdeItens > 1) ? ' itens' : ' item';

            var codigosNaoEncontrados = response.data.CodigosNaoEncontrados;

            if (codigosNaoEncontrados && codigosNaoEncontrados.length > 0) {
              var mensagemProdNaoEncontrado =
                codigosNaoEncontrados.length > 1
                  ? 'Os seguintes produtos não foram encontrados: '
                  : 'O seguinte produto não foi encontrado: ';
              mensagemProdNaoEncontrado += '<br/><br/>' + response.data.CodigosNaoEncontrados.join(', ');
              swal.exibirMensagemAlerta(mensagemProdNaoEncontrado);
            }

            self.$emit('retornarProdutos', response.data.Produtos);
          })
          .catch((error) => {
            loading.ocultar();
            swal.exibirMensagemErro('Ocorreu um problema');
            return;
          });
      },
      enviarArquivoComLayout(payloadBodyFormData){
        var self = this;
        loading.exibir();
        this.$store
          .dispatch('devolucao/obterItensUploadArquivoPorTipo', payloadBodyFormData)
          .then((response) => {
            loading.ocultar();

            if (response.data?.Criticas?.length) {
              self.modalCriticas.listaCriticas = response.data.Criticas;
              self.modalCriticas.isShow = true;
              return;
            }

            self.Arquivo = {};
            var qtdeItens = response.data.Produtos.length;

            self.MensagemBuscaLimitada = '';

            if (qtdeItens > 0)
              self.MensagemBuscaLimitada = 'Exibindo ' + response.data.Produtos.length + (qtdeItens > 1) ? ' itens' : ' item';

            let produtos = response.data.Produtos.map((prod) => {
              return { ...prod, TipoArquivoReferenciaEntrada: response.data.TipoArquivoReferenciaEntrada };
            });

            self.$emit('retornarProdutos', produtos );
          })
          .catch((error) => {
            loading.ocultar();
            swal.exibirMensagemErro('Ocorreu um problema');
            return;
          });
      },
      carregarListaTiposArquivoUpload() {
        this.filtroTipoArquivo.opcoes = Object.keys(this.tiposArquivosUpload).map(key => {
          let type = this.tiposArquivosUpload[key];
          return { Id: type.Id, Descricao: type.Descricao };
        });

        if (!this.controlaLote)
          this.filtroTipoArquivo.opcoes = this.filtroTipoArquivo.opcoes.filter(o => o.Id != 5);

        this.filtroTipoArquivo.selecionado = this.filtroTipoArquivo.opcoes[0];
      },
      fecharModalCriticas() {
        this.modalCriticas.isShow = false;
        this.removerArquivo();
      },
    },
  };
</script>
<style scoped></style>
